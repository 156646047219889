import { Stripe, StripeCardNumberElement } from '@stripe/stripe-js'
import { useRollbar } from '@rollbar/react'
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { BadRequest, InternalError, NetworkError } from 'common/errors'
import usePayment, {
  selectors as paymentSelectors,
} from 'publisher/hooks/usePayment'
import { OldEntityInterface } from '../../common/types/entities/OldEntityInterface'
import { optInFail } from '../actions/optInActions'
import * as api from '../api/stripe'
import { confirmPaymentIntent } from '../api/stripe'
import { IntentTypeEnum } from '../enums/IntentTypeEnum'
import usePage, { selectors as pageSelectors } from './usePage'
import usePaymentSubmit from './usePaymentSubmit'

function getStripeConfirmHandler(stripe: Stripe, type: IntentTypeEnum) {
  return type === IntentTypeEnum.setup
    ? stripe.confirmCardSetup
    : stripe.confirmCardPayment
}

export default function useStripeCardPaymentHandler(
  entity: OldEntityInterface,
) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const rollbar = useRollbar()
  const stripe = useStripe()
  const elements = useElements()
  const purchaseProcessId = usePayment(paymentSelectors.getPurchaseProcessId)
  const pageId = usePage(pageSelectors.getPageId)
  const { errors, setErrors, isLoading, submit } = usePaymentSubmit(entity)

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (!stripe || !elements) {
      return
    }

    await submit(async body => {
      try {
        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(
            CardNumberElement,
          ) as StripeCardNumberElement,
        })

        if (error) {
          // @see https://systeme-team.slack.com/archives/C6UURE03D/p1670840092361989?thread_ts=1668177169.203109&cid=C6UURE03D
          setErrors([error.message as string])
          // setErrors([
          //   t('components.payment_methods.stripe.invalid_card_details'),
          // ])
          return
        }

        if (!paymentMethod) {
          throw Error('undefined payment method')
        }

        const { data: mainOfferData } = await api.buyMainOffer(
          pageId,
          purchaseProcessId,
          {
            payment_form: {
              ...body,
              paymentMethodId: paymentMethod.id,
            },
          },
        )

        if (mainOfferData.intentSecret) {
          const mainOfferStripeConfirmHandler = getStripeConfirmHandler(
            stripe,
            mainOfferData.intentType,
          )

          const confirmCardPaymentData = mainOfferData.paymentMethodId
            ? { payment_method: mainOfferData.paymentMethodId }
            : undefined

          const { error } = await mainOfferStripeConfirmHandler(
            mainOfferData.intentSecret,
            confirmCardPaymentData,
          )

          if (!error) {
            const { data: bumpData } = await confirmPaymentIntent(
              pageId,
              purchaseProcessId,
            )
            // bump case
            if (bumpData.intentSecret) {
              const bumpOfferStripeConfirmHandler = getStripeConfirmHandler(
                stripe,
                bumpData.intentType,
              )

              const confirmBumpOfferPaymentData = bumpData.paymentMethodId
                ? { payment_method: bumpData.paymentMethodId }
                : {
                    payment_method: {
                      card: elements.getElement(
                        CardNumberElement,
                      ) as StripeCardNumberElement,
                    },
                  }

              const { error } = await bumpOfferStripeConfirmHandler(
                bumpData.intentSecret,
                confirmBumpOfferPaymentData,
              )

              if (!error) {
                const { data } = await confirmPaymentIntent(
                  pageId,
                  purchaseProcessId,
                )
                window.location.assign(data.redirect)
                return
              }
            }

            // redirect to upSell or thank you page
            window.location.assign(mainOfferData.redirect)
          } else {
            setErrors([error.message as string])
          }
        } else {
          // redirect to upSell or thank you page
          window.location.href = mainOfferData.redirect
        }
      } catch (error) {
        if (error instanceof BadRequest) {
          setErrors(error.response.data.errors.common)
          dispatch(optInFail({ fields: error.response.data.errors.fields }))
        } else if (error instanceof NetworkError) {
          setErrors([t('core.errors.no_connection')])
        } else if (error instanceof InternalError) {
          setErrors([t('core.error.title')])
        } else {
          rollbar.captureEvent(body, 'debug')
          rollbar.error('Stripe card payment failed', error as Error)
        }
      }
    })
  }

  return { errors, isLoading, handleSubmit }
}
