import Rollbar from 'rollbar'
import { TFunction } from 'react-i18next'
import { formOptions } from 'publisher/context/mercadoPago/config/formOptions'
import { MercadoPageFormError } from 'publisher/context/mercadoPago/types/MercadoPagoFormErrorInterface'
import {
  MercadoPagoCardFormInterface,
  MercadoPagoFieldErrorInterface,
  ValidityChangeResponseInterface,
} from 'publisher/context/mercadoPago/types/MercadoPagoInterface'
import { getFieldError } from 'publisher/context/mercadoPago/utils/getFieldError'
import {
  MercadoPago,
  MercadoPagoServerError,
} from 'publisher/context/mercadoPagoContext'

export const initCardForm = (
  mercadoPago: MercadoPago,
  pricePlanAmount: number,
  addError: (
    field: MercadoPagoFieldErrorInterface['field'],
    message: string,
  ) => void,
  resetError: (field: MercadoPagoFieldErrorInterface['field']) => void,
  t: TFunction,
  rollbar: Rollbar,
): MercadoPagoCardFormInterface => {
  return mercadoPago.cardForm({
    amount: `${pricePlanAmount}`,
    form: formOptions,
    callbacks: {
      onFormMounted: (error: any) => {
        if (error) {
          rollbar.error(`Mercadopago payment mount failed`, error)
        }
      },
      onValidityChange: (
        errors?: ValidityChangeResponseInterface[],
        field?: MercadoPagoFieldErrorInterface['field'],
      ) => {
        // attention, unstable responses
        if (errors && errors.length > 0 && field) {
          errors.forEach(error => {
            const fieldError = getFieldError(error.code)
            if (fieldError) {
              addError(fieldError.field, t(fieldError.message))
            }
          })
        } else if (field) {
          resetError(field)
        }
      },
      onCardTokenReceived: (
        errors?: MercadoPagoServerError | MercadoPageFormError[] | string,
      ) => {
        if (typeof errors === 'string') {
          rollbar.error('onCardTokenReceived error', { errors })
        } else if (
          typeof errors === 'object' &&
          (errors as MercadoPagoServerError).cause
        ) {
          ;(errors as MercadoPagoServerError).cause.forEach(cause => {
            const error = getFieldError(cause.code)
            if (error) {
              const { field, message } = error
              addError(field, t(message))
            }
          })
        } else if (Array.isArray(errors)) {
          Object.values(errors).forEach(error => {
            const fieldError = getFieldError(error.code)
            if (fieldError) {
              const { field, message } = fieldError
              addError(field, t(message))
            }
          })
        }
      },
      onSubmit: async (event: any) => {
        event.preventDefault()
      },
    },
  })
}
